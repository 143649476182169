<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <v-btn depressed color="primary" @click="createProject">{{ $t("projects.new") }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-container fluid grid-list-xl>
          <v-layout wrap justify-space-around>
            <v-flex v-for="(item, index) in projects" :key="index">
              <v-card color="gray" width="230px" height="300px">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="headline">{{ item.title }} &nbsp;</v-list-item-title>
                    <v-list-item-subtitle>{{ item.author.name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <div class="preview-placeholder">
                  <v-img class="no-aa"
                         :src="'/api/projects/preview/' + item.id + '?v=' + item.time_updated"
                         style="max-width: 230px;max-height: 100%;"
                         v-if="item.meta" contain/>
                </div>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                    icon
                    @click="applyAction(action.type, item)"
                    v-for="action in item.actions"
                    :title="action.title"
                  >
                    <i :class="`fas fa-` + action.icon"></i>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
            <v-flex v-for="(item, index) in projects" :key="'empty-' + index"
                    class="flex-empty">
              <div></div>
            </v-flex>
          </v-layout>
        </v-container>
        <div class="text-center pt-2">
          <v-pagination @input="getDataFromApi" v-model="page" :length="totalPages"></v-pagination>
        </div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Projects",
  data() {
    return {
      page: 1,
      totalPages: 1,
      projects: [],
    }
  },
  computed: {
    // ...mapGetters(['adminProjects', 'adminProjectsPages'])
  },
  methods: {
    async createProject() {
      const result = await this.$api.projects.create()
      this.openEditor(result.project_id)
    },
    openEditor(id) {
      this.$router.push({name: 'admin_project_design', params: {id: id}});
    },
    async applyAction(actionType, item) {
      if (actionType == 'edit') {
        this.openEditor(item.id)
      } else if (actionType == 'preview') {
        this.$router.push({name: 'admin_project_preview', params: {id: item.id}});
      } else if (actionType == 'copy') {
        this.$store.commit('app/setLoading', true)
        const response = await this.$api.projects.copy({project_id: item.id});
        await this.getDataFromApi()
        this.$store.commit('app/setLoading', false)
      }
    },
    async getDataFromApi() {
      const response = await this.$api.projects.getMy({page: this.page});
      this.projects = response.projects;
      this.totalPages = response.total_pages;
    },
  },
  async created() {
    this.$store.commit('app/setLoading', true)
    await this.getDataFromApi();
    this.$store.commit('app/setLoading', false)
  }

}
</script>

<style lang="scss" scoped>
.flex {
  flex-grow: 0;
}

.flex-empty {
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;


  div {
    width: 230px;
  }
}

.preview-placeholder {
  height: 170px;
  overflow: hidden;
}
</style>
